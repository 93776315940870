import {
  EMPTY,
  MonoTypeOperatorFunction,
  NEVER,
  catchError,
  from,
  lastValueFrom,
  of,
  switchMap,
} from 'rxjs';
import { createUpdateCdn$ } from './cdn.js';
import { indicateInterest } from './distributed-interest.js';
import { removeLocalStorageValue } from './local-storage.js';
import { clearVersionContext } from './version-context.js';
import { flog } from './helpers.js';

export async function logOut({ skipCdn = false } = {}) {
  return terminateHeimdallSession(false, skipCdn);
}

export async function optOut({ skipCdn = false } = {}) {
  return terminateHeimdallSession(true, skipCdn);
}

async function terminateHeimdallSession(
  includeAuthMode = false,
  skipCdn = false
) {
  removeLocalStorageValue('heimdall:token:idp');
  removeLocalStorageValue('heimdall:preferred-idp');
  removeLocalStorageValue('heimdall:observed-server-drift-milliseconds');
  clearVersionContext();
  return lastValueFrom(
    skipCdn
      ? of('skip').pipe(flog('Skip CDN clearing on logout update'))
      : createUpdateCdn$('', includeAuthMode).pipe(catchError(() => EMPTY)),
    {
      defaultValue: 'ignored 🧷',
    }
  ).then(() => {
    indicateInterest('heimdall:idp');
  });
}

export function logoutOnError<T>(
  fallback: T | undefined = undefined,
  skipCdn = false
): MonoTypeOperatorFunction<T> {
  return catchError((e) => {
    // eslint-disable-next-line no-console
    console.warn(`Unexpected authorization error -- cleaning up`, e);
    return from(logOut({ skipCdn })).pipe(
      switchMap(() => {
        if (fallback !== undefined) {
          return of(fallback);
        }
        return NEVER;
      })
    );
  });
}
