import { map, of } from 'rxjs';
import type { Observable } from 'rxjs';

export type GenericRedirectionResult = {
  state?: string;
  adminConsent?: boolean;
} & (
  | {
      result: 'SUCCESS';
      tenant?: string;
      scope?: string;
    }
  | {
      result: 'ERROR';
      errorCode: string;
      errorDescription?: string;
    }
);

export function createGenericRedirectionResult$(): Observable<GenericRedirectionResult> {
  return of(globalThis.location.search).pipe(map(toRedirectionResult));
}

export function toRedirectionResult(search: string): GenericRedirectionResult {
  const params = new URLSearchParams(search);
  const tenant = params.get('tenant');
  const state = params.get('state');
  const scope = params.get('scope');
  const adminConsent = params.get('admin_consent') === 'True';

  const errorCode = params.get('error');
  const errorDescription = params.get('error_description');

  const result = {
    ...(state && { state }),
    ...(adminConsent && { adminConsent }),
  };

  return errorCode
    ? {
        result: 'ERROR',
        errorCode,
        ...(errorDescription && { errorDescription }),
        ...result,
      }
    : {
        result: 'SUCCESS',
        ...result,
        ...(tenant && { tenant }),
        ...(scope && { scope }),
      };
}
