import '@refinitiv-ui/elements/button';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../components/heimdall-error.js';
import '../components/heimdall-loader.js';
import '../components/login-frame.js';
import {
  createGenericRedirectionResult$,
  type GenericRedirectionResult,
} from '../generic-redirection.js';
import '../theme.js';

@customElement('heimdall-redirect-result')
export class HeimdallRedirectResult extends LitElement {
  @state()
  _redirectionResult?: GenericRedirectionResult;

  firstUpdated() {
    createGenericRedirectionResult$().subscribe((redirectionResult) => {
      // eslint-disable-next-line no-console
      console.log(`[HEIMDALL] Redirection result:`, redirectionResult);
      this._redirectionResult = redirectionResult;
    });
  }

  render() {
    return html`<login-frame> ${this.getFrameContent()} </login-frame>`;
  }

  getFrameContent() {
    if (!this._redirectionResult) {
      return html`<heimdall-loader />`;
    }

    if (this._redirectionResult.result == 'ERROR') {
      const { errorCode, errorDescription } = this._redirectionResult;
      return html`<heimdall-error
        title="Something went wrong."
        .error=${`${errorDescription || ''} ${
          errorCode ? `[${errorCode}]` : ''
        }`}
      ></heimdall-error>`;
    }

    return html`<h2>You're all set!</h2>
      <p>You can now close this window.</p>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-redirect-result': HeimdallRedirectResult;
  }
}
