import { ServerResponseError } from '@heimdall/client';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('heimdall-error')
export class HeimdallError extends LitElement {
  @property({ type: Object })
  error: Error = new Error('Unknown error.');

  @property({ type: String })
  title = 'We’re having trouble signing you in.';

  getErrorText(error: Error) {
    if (error instanceof ServerResponseError) {
      const errorRef =
        error.correlationId || error.type
          ? `(Error reference: ${
              error.correlationId ? error.correlationId : error.type
            })`
          : '';
      switch (error.type) {
        case 'invalid_request':
          return `Invalid request. ${errorRef}`;
        case 'invalid_user':
          return `Invalid account. ${errorRef}`;
        case 'expired_data':
          return `Expired data. ${errorRef}`;
        default:
          return `${error.message} ${errorRef}`;
      }
    } else if (error instanceof Error) {
      return error.message;
    } else {
      return error || 'Unknown error.';
    }
  }

  render() {
    return html`<h2>${this.title}</h2>
      <p>${this.getErrorText(this.error)}</p>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-error': HeimdallError;
  }
}
